import { Stack } from '@mui/material'

export const Sitemap = () => {
	return (
		<Stack justifyContent="center" gap={1} alignItems="center" pt={4}>
			<h1>Generate Sitemap</h1>
			<a href="/sitemap.xml" download>
				Download Sitemap
			</a>
		</Stack>
	)
}
